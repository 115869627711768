/* You can add global styles to this file, and also import other style files */

:root {
  --color-g100-trip: 131, 189, 94;
  --color-g200-trip: 47, 85, 22;
  --color-g300-trip: 0, 52, 7;

  --color-n100-trip: 48, 48, 48;
  --color-n101-trip: 29, 29, 29;
  --color-n200-trip: 153, 153, 153;
  --color-n300-trip: 241, 241, 241;

  --color-black-trip: 0, 0, 0;
  --color-white-trip: 255, 255, 255;

  --color-g100: rgb(var(--color-g100-trip));
  --color-g200: rgb(var(--color-g200-trip));
  --color-g300: rgb(var(--color-g300-trip));

  --color-n100: rgb(var(--color-n100-trip));
  --color-n101: rgb(var(--color-n101-trip));
  --color-n200: rgb(var(--color-n200-trip));
  --color-n300: rgb(var(--color-n300-trip));

  --color-black: rgb(var(--color-black-trip));
  --color-white: rgb(var(--color-white-trip));

  --color-text: var(--color-white);
  --color-text-link: var(--color-g100);
  --color-text-subdued: var(--color-n200);
  --color-text-very-subdued: #565656;

  --color-background-primary: var(--color-black);
  --color-background-secondary: var(--color-n100);
  --color-background-tertiary: var(--color-n101);

  --color-button-primary-bg: var(--color-g100);
  --color-button-primary-border: var(--color-g100);
  --color-button-primary-text: var(--color-g300);

  --color-button-secondary-bg: transparent;
  --color-button-secondary-border: var(--color-white);
  --color-button-secondary-text: var(--color-white);

  --color-button-tertiary-bg: rgba(var(--color-white-trip), 0.7);
  --color-button-tertiary-border: var(--color-g300);
  --color-button-tertiary-text: var(--color-g300);

  --color-button-quaternary-bg: transparent;
  --color-button-quaternary-border: transparent;
  --color-button-quaternary-text: var(--color-white);

  --color-hero-content-bg: var(--color-g100);
  --color-hero-content-heading: var(--color-g300);
  --color-hero-content-text: #005b0d;
  --color-hero-content-text-desktop: var(--color-white);

  --color-nav-bg: var(--color-n101);
  --color-nav-text: var(--color-white);

  --desktop-break: 768px;
}

html,
body {
  height: 100%;
  background-color: black;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  font-weight: 400;
  color: white;
}

textarea,
select,
input:not([type='checkbox']):not([type='radio']) {
  background-color: white;
  display: block;
  width: 100%;
  font-size: 14px;
  padding: 8px;
  border-radius: 4px;
  border: 2px solid #404040;
  margin: 8px 0;
  box-sizing: border-box;
}

textarea,
select,
input {
  touch-action: manipulation;
  user-select: none;
  user-scalable: no;
  -webkit-user-scalable: no;
}

.m-t-0 {
  margin-top: 0;
}
